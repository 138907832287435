export function MandatoryValidator(value) {
  if (value) {
    return undefined;
  }
  return "Champ obligatoire !";
}

export function MinionValidator(value) {
  if (!value) {
    return "Champ obligatoire !";
  }
  if (value.split(".").length !== 2 && !value.endsWith(".alkivi.fr")) {
    return "Nom incorrect, doit être de la forme nom.client (où nom et client vous sont propres)";
  }
  if (value.match(/[^a-zA-Z0-9\-.]/)) {
    return "Nom incorrect, doit être de la forme nom.client (où nom et client vous sont propres)";
  }
  return undefined;
}
