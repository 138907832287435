/* eslint-disable react/no-this-in-sfc */
import React from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { Form, FormGroup, Label, Button, Col, Row, Input } from "reactstrap";
import { FaHome, FaStepBackward, FaStepForward } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import sharedProcessState from "./State";
import stepProps from "./Step";
import { MinionValidator } from "../../utils/validators";
import Error from "../utils/Error";
import { api } from "../../services";
import Footer from "./Footer";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Step = ({ nextStep, goToStep }) => {
  const { os, setMinion, setMosyleURL } = sharedProcessState();
  const query = useQuery();
  const minion = query.get("minion", null);
  const { t } = useTranslation();

  if (!os) {
    return <div />;
  }

  const onSubmit = (values) => {
    setMinion(values.minion);
    nextStep();
  };

  let testClearTimeout = null;

  const getMinionName = (testMinion) =>
    new Promise((resolve, reject) => {
      if (testClearTimeout) testClearTimeout();
      const timerId = setTimeout(() => {
        api.getMinionName(testMinion).then((res) => {
          const { response, error } = res;
          if (!error) {
            setMosyleURL(response.mosyleUrl);
            resolve();
          } else {
            reject(error);
          }
        });
      }, 500);
      testClearTimeout = () => {
        clearTimeout(timerId);
        resolve();
      };
    });

  const validate = (values) => {
    const errors = {};
    const { recaptcha } = values;
    const formMinion = values.minion;
    if (!recaptcha) {
      errors.recaptcha = t("check.form.name.error1");
      return errors;
    }
    return getMinionName(formMinion).catch(() => {
      errors.minion = t("check.form.name.error2");
      return errors;
    });
  };
  return (
    <FinalForm
      initialValues={{
        minion,
      }}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <h2>{t("check.title")}</h2>
          </Row>
          <FormGroup row>
            <Label for="minion" sm={2}>
              {t("check.form.name.title")}
            </Label>
            <Col sm={10}>
              <Field name="minion" validate={MinionValidator}>
                {({ input, meta }) => (
                  <div>
                    <Input
                      {...input}
                      type="text"
                      placeholder={t("check.form.name.placeholder")}
                      invalid={meta.error && meta.touched}
                      valid={meta.valid}
                    />
                    <Error name="minion" />
                  </div>
                )}
              </Field>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="recaptcha" sm={2}>
              {t("check.form.check.title")}
            </Label>
            <Col sm={10}>
              <Field name="recaptcha">
                {({ input }) => (
                  <div>
                    <ReCAPTCHA
                      onChange={input.onChange}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    />
                    <Error name="recaptcha" />
                  </div>
                )}
              </Field>
            </Col>
          </FormGroup>
          <Row>
            <Col>
              <Button type="submit" color="primary" disabled={submitting}>
                {t("global.next")} <FaStepForward />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button color="secondary" onClick={() => goToStep(1)}>
                ️<FaStepBackward /> {t("global.previous")}
              </Button>
              &nbsp;
              <Button color="secondary" onClick={() => goToStep(1)}>
                ️<FaHome /> {t("global.home")}
              </Button>
            </Col>
          </Row>
          <Footer />
        </Form>
      )}
    />
  );
};
Step.propTypes = stepProps;

export default Step;
