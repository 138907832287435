import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as notificationsReducer } from "react-notification-system-redux";
import { loadingBarReducer } from "react-redux-loading-bar";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    notifications: notificationsReducer,
    loadingBar: loadingBarReducer,
  });
