import React from "react";
import { success, error } from "react-notification-system-redux";

export function displayError(e) {
  const notification = {
    title: `Error ${e.message}`,
    message: "",
    autoDismiss: 5,
    children: "",
  };

  if ("reason" in e) {
    notification.message += e.reason;
  }

  if ("errors" in e) {
    notification.children = Object.keys(e.errors).map((key) => (
      <div key={key}>
        <p className="font-weight-bold">{key}</p>
        <p>{e.errors[key]}</p>
      </div>
    ));
  }

  return display(error, notification);
}

export function displaySuccess(message) {
  const notification = {
    title: "Success",
    message,
    autoDismiss: 5,
  };

  return display(success, notification);
}

export function display(callback, notification) {
  return callback(notification);
}
