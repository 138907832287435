import React, { useState } from "react";
import {
  FaCheck,
  FaClipboard,
  FaExclamationTriangle,
  FaHome,
  FaLink,
  FaStepBackward,
  FaStepForward,
  FaTimes,
} from "react-icons/fa";
import { Button, Col, Input, InputGroup, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "@vigosan/react-copy-to-clipboard";

import sharedProcessState from "./State";
import stepProps from "./Step";
import Footer from "./Footer";

const Step = ({ previousStep, nextStep, goToStep }) => {
  const { minion, os, mosyleURL } = sharedProcessState();
  const [click, setClick] = useState(false);
  const { t } = useTranslation();

  if (!os || !minion) {
    return <div />;
  }

  let buttonDisplay = <FaClipboard />;
  let buttonText = t("process.copy_todo");
  let buttonColor = "warning";

  if (click === "success") {
    buttonDisplay = <FaCheck />;
    buttonText = t("process.copy_done");
    buttonColor = "success";
  } else if (click === "error") {
    buttonDisplay = <FaTimes />;
    buttonText = t("process.copy_error");
    buttonColor = "danger";
  }

  let url = null;
  if (os === "mac") {
    url =
      "https://support.alkivi.fr/support/solutions/articles/13000095161-installation-de-l-agent-alkivi-mac";
  } else if (os === "windows") {
    url =
      "https://support.alkivi.fr/support/solutions/articles/13000095162-installation-de-l-agent-alkivi-windows";
  } else if (os === "linux") {
    url =
      "https://support.alkivi.fr/fr/support/solutions/articles/13000101566-installation-de-l-agent-alkivi-linux";
  }

  let osSpecificText = <div />;
  let textInput = null;

  if (os === "mac") {
    const baseCode = "https://join.mosyle.com/?account=";
    let code = `${baseCode}alkivi`;
    if (mosyleURL) {
      code = baseCode + mosyleURL;
    }

    osSpecificText = (
      <CopyToClipboard
        onCopy={({ success }) => {
          if (success) {
            setClick("success");
            setTimeout(() => {
              setClick(false);
            }, 5000);
          } else {
            setClick("error");
          }
        }}
        render={({ copy }) => (
          <InputGroup>
            <Input
              disabled
              value={code}
              ref={(input) => {
                textInput = input;
              }}
            />
            <Button
              onClick={() => copy(textInput.props.value)}
              color={buttonColor}
            >
              {buttonDisplay} {buttonText}
            </Button>
          </InputGroup>
        )}
      />
    );
  } else if (os === "windows") {
    const code = `Set-ExecutionPolicy Bypass -Scope Process -Force; iex ((New-Object System.Net.WebClient).DownloadString('${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/minions/${minion}/bootstrap.ps1'))`;
    osSpecificText = (
      <CopyToClipboard
        onCopy={({ success }) => {
          if (success) {
            setClick("success");
            setTimeout(() => {
              setClick(false);
            }, 5000);
          } else {
            setClick("error");
          }
        }}
        render={({ copy }) => (
          <InputGroup>
            <Input
              disabled
              value={code}
              ref={(input) => {
                textInput = input;
              }}
            />
            <Button
              onClick={() => copy(textInput.props.value)}
              color={buttonColor}
            >
              {buttonDisplay} {buttonText}
            </Button>
          </InputGroup>
        )}
      />
    );
  } else if (os === "linux") {
    const code = `curl -L  ${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/minions/${minion}/bootstrap.sh | sudo sh`;
    osSpecificText = (
      <CopyToClipboard
        onCopy={({ success }) => {
          if (success) {
            setClick("success");
            setTimeout(() => {
              setClick(false);
            }, 5000);
          } else {
            setClick("error");
          }
        }}
        render={({ copy }) => (
          <InputGroup>
            <Input
              disabled
              value={code}
              ref={(input) => {
                textInput = input;
              }}
            />
            <Button
              onClick={() => copy(textInput.props.value)}
              color={buttonColor}
            >
              {buttonDisplay} {buttonText}
            </Button>
          </InputGroup>
        )}
      />
    );
  }

  const displayOs = os[0].toUpperCase() + os.substring(1);

  return (
    <div>
      <Row>
        <Col>
          <h2>
            {t("process.title")} - {displayOs}
          </h2>
          <p>
            <FaExclamationTriangle /> <b>Important : </b>
            {t("process.warning")} <FaExclamationTriangle />
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <ul className="process-list">
            <li>{t("process.step1")}</li>
            <li>{osSpecificText}</li>
            <li>
              {t("process.step2")}{" "}
              <a href={url} target="new">
                {t("process.step2bis")} <FaLink />
              </a>
              .
            </li>
            <li>{t("process.step3")}</li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button type="submit" color="primary" onClick={nextStep}>
            {t("global.next")} <FaStepForward />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button color="secondary" onClick={previousStep}>
            ️<FaStepBackward /> {t("global.previous")}
          </Button>
          &nbsp;
          <Button color="secondary" onClick={() => goToStep(1)}>
            ️<FaHome /> {t("global.home")}
          </Button>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};
Step.propTypes = stepProps;

export default Step;
