import React, { useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { Form, FormGroup, Label, Button, Col, Row, Input } from "reactstrap";
import { FaStepBackward, FaStepForward, FaHome, FaInfo } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import sharedProcessState from "./State";
import stepProps from "./Step";
import { MandatoryValidator, MinionValidator } from "../../utils/validators";
import Error from "../utils/Error";
import LongLoading from "../utils/LongLoading";
import { api } from "../../services";
import Footer from "./Footer";

const Step = ({ nextStep, goToStep, previousStep }) => {
  const { os, minion } = sharedProcessState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (!os || !minion) {
    return <div />;
  }

  if (loading) {
    return (
      <div>
        <Row>
          <h3>Loading</h3>
        </Row>
        <Row>
          <LongLoading />
        </Row>
      </div>
    );
  }

  const onSubmit = (values) => {
    setLoading(true);
    const sendMinion = values.minion;
    const data = { ...values };
    delete data.minion;
    api.postMinionTicket(sendMinion, data).then((res) => {
      const { error } = res;
      if (!error) {
        setLoading(false);
        nextStep();
      }
    });
  };

  return (
    <FinalForm
      initialValues={{
        minion,
        os,
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        let macSpecific = <div />;
        if (os === "mac") {
          macSpecific = (
            <div>
              <FormGroup row>
                <Label for="serial" sm={2}>
                  {t("informations.form.serial")}{" "}
                  <a
                    href="https://support.apple.com/guide/mac-help/how-do-i-find-the-serial-number-mchlp1695/mac"
                    target="new"
                  >
                    <FaInfo />
                  </a>
                </Label>
                <Col sm={10}>
                  <Field name="serial" validate={MandatoryValidator}>
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          type="text"
                          invalid={meta.error && meta.touched}
                        />
                        <Error name="serial" />
                      </div>
                    )}
                  </Field>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="login" sm={2}>
                  {t("informations.form.user")}
                </Label>
                <Col sm={10}>
                  <Field name="login" validate={MandatoryValidator}>
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          type="text"
                          invalid={meta.error && meta.touched}
                        />
                        <Error name="login" />
                      </div>
                    )}
                  </Field>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="password" sm={2}>
                  {t("informations.form.password")}
                </Label>
                <Col sm={10}>
                  <Field name="password">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          type="password"
                          invalid={meta.error && meta.touched}
                        />
                        <Error name="password" />
                      </div>
                    )}
                  </Field>
                </Col>
              </FormGroup>
            </div>
          );
        }

        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <h2>{t("informations.title")}</h2>
              </Col>
            </Row>
            <FormGroup row>
              <Label for="minion" sm={2}>
                {t("informations.form.title")}
              </Label>
              <Col sm={10}>
                <Field name="minion" validate={MinionValidator}>
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        type="text"
                        disabled
                        invalid={meta.error && meta.touched}
                      />
                      <Error name="minion" />
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="os" sm={2}>
                {t("informations.form.os")}
              </Label>
              <Col sm={10}>
                <Field name="os" validate={MandatoryValidator}>
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        type="text"
                        disabled
                        invalid={meta.error && meta.touched}
                      />
                      <Error name="os" />
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="firstname" sm={2}>
                {t("informations.form.firstname")}
              </Label>
              <Col sm={10}>
                <Field name="firstname" validate={MandatoryValidator}>
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        type="text"
                        invalid={meta.error && meta.touched}
                      />
                      <Error name="firstname" />
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="lastname" sm={2}>
                {t("informations.form.lastname")}
              </Label>
              <Col sm={10}>
                <Field name="lastname" validate={MandatoryValidator}>
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        type="text"
                        invalid={meta.error && meta.touched}
                      />
                      <Error name="lastname" />
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" sm={2}>
                {t("informations.form.email")}
              </Label>
              <Col sm={10}>
                <Field name="email" validate={MandatoryValidator}>
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        type="email"
                        invalid={meta.error && meta.touched}
                      />
                      <Error name="email" />
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="mobile" sm={2}>
                {t("informations.form.phone")}
              </Label>
              <Col sm={10}>
                <Field name="mobile">
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        type="phone"
                        invalid={meta.error && meta.touched}
                      />
                      <Error name="mobile" />
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>
            {macSpecific}
            <FormGroup row>
              <Label for="comment" sm={2}>
                {t("informations.form.comment")}
              </Label>
              <Col sm={10}>
                <Field name="comment">
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        type="text"
                        invalid={meta.error && meta.touched}
                      />
                      <Error name="comment" />
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>
            <Row>
              <Col>
                <Button type="submit" color="primary" disabled={submitting}>
                  {t("global.next")} <FaStepForward />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button color="secondary" onClick={previousStep}>
                  ️<FaStepBackward /> {t("global.previous")}
                </Button>
                &nbsp;
                <Button color="secondary" onClick={() => goToStep(1)}>
                  ️<FaHome /> {t("global.home")}
                </Button>
              </Col>
            </Row>
            <Footer />
          </Form>
        );
      }}
    />
  );
};
Step.propTypes = stepProps;

export default Step;
