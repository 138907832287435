import { useCallback, useState } from "react";
import { useBetween } from "use-between";

const processState = () => {
  const [minion, setMinion] = useState(null);
  const [os, setOs] = useState(null);
  const [mosyleURL, setMosyleURL] = useState("alkivi");
  const [status, setStatus] = useState(null);
  const testOs = useCallback((newOs, func) => {
    setOs(newOs);
    if (func) {
      func();
    }
  });
  return {
    minion,
    setMinion,
    os,
    setOs,
    status,
    setStatus,
    testOs,
    mosyleURL,
    setMosyleURL,
  };
};

const sharedProcessState = () => useBetween(processState);
export default sharedProcessState;
