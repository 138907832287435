import React from "react";
import { Row, Col, Card, CardTitle, Button } from "reactstrap";
import { FaWindows, FaApple, FaLinux, FaStepForward } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import sharedProcessState from "./State";
import stepProps from "./Step";
import Footer from "./Footer";

const Step = ({ nextStep }) => {
  const { setOs } = sharedProcessState();
  const { t } = useTranslation();

  const onClick = (os) => {
    setOs(os);
    nextStep();
  };

  return (
    <div>
      <Row>
        <Col>
          <h2>{t("system")}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="text-center" sm="4">
          <Card body className="text-center">
            <CardTitle>
              <div>
                <FaApple size={84} />
                <h1>Mac</h1>
              </div>
            </CardTitle>
            <Button color="primary" onClick={() => onClick("mac")}>
              {t("global.next")} <FaStepForward />
            </Button>
          </Card>
        </Col>
        <Col className="text-center" sm="4">
          <Card body className="text-center">
            <CardTitle>
              <div>
                <FaWindows size={84} />
                <h1>Windows</h1>
              </div>
            </CardTitle>
            <Button color="primary" onClick={() => onClick("windows")}>
              {t("global.next")} <FaStepForward />
            </Button>
          </Card>
        </Col>
        <Col className="text-center" sm="4">
          <Card body className="text-center">
            <CardTitle>
              <div>
                <FaLinux size={84} />
                <h1>Linux</h1>
              </div>
            </CardTitle>
            <Button color="primary" onClick={() => onClick("linux")}>
              {t("global.next")} <FaStepForward />
            </Button>
          </Card>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};
Step.propTypes = stepProps;

export default Step;
