/* eslint react/forbid-prop-types: 0 */

import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Switch } from "react-router";

import Home from "./Home";
import Bootstrap from "./Bootstrap";

import PublicRoute from "./PublicRoute";

import "../styles/root.css";

const Root = ({ store, history }) => (
  <Suspense fallback="loading">
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <PublicRoute exact path="/" component={Home} />
          <PublicRoute exact path="/bootstrap" component={Bootstrap} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </Suspense>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
