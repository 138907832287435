import React from "react";
import PropTypes from "prop-types";

import Notifications from "react-notification-system-redux";

// Optional styling
const style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: "10px 5px 2px 1px",
    },
  },
};

const NotificationsSystem = ({ notifications }) => (
  <Notifications notifications={notifications} style={style} />
);

NotificationsSystem.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NotificationsSystem;
