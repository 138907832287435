import React from "react";
import PropTypes from "prop-types";
import { Container, Progress, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import StepWizard from "react-step-wizard";
import Step01 from "./process/Step01";
import Step04 from "./process/Step04";
import Step05 from "./process/Step05";
import Step06 from "./process/Step06";
import Step07 from "./process/Step07";

const Nav = ({ currentStep, totalSteps }) => {
  const { t } = useTranslation();

  let percentage = Math.round(((totalSteps - currentStep) / totalSteps) * 100);
  if (currentStep === 1) {
    percentage = 100;
  }
  return (
    <Row>
      <Col>
        <h1>{t("title")}</h1>
        <Progress value={100 - percentage}>{100 - percentage}%</Progress>
        <hr />
      </Col>
    </Row>
  );
};
Nav.propTypes = {
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
};
Nav.defaultProps = {
  currentStep: 1,
  totalSteps: 10,
};

const Bootstrap = () => (
  <Container>
    <StepWizard nav={<Nav />}>
      <Step01 />
      <Step04 />
      <Step05 />
      <Step06 />
      <Step07 />
    </StepWizard>
  </Container>
);

export default Bootstrap;
