import React from "react";

import PropTypes from "prop-types";
import { Field } from "react-final-form";

const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) => {
      if (touched && error) {
        return <span>{error}</span>;
      }
      return null;
    }}
  />
);
Error.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Error;
