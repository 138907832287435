import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import LoadingBar from "react-redux-loading-bar";

import Navbar from "./Navbar";
import Breadcrumbs from "./Breadcrumbs";
import Notifications from "../containers/Notifications";

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <div>
        <Navbar />
        <LoadingBar style={{ backgroundColor: "red", height: "3px" }} />
        <Breadcrumbs />
        <Notifications />
        <Component {...props} />
      </div>
    )}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PublicRoute;
