import React from "react";
import { useTranslation } from "react-i18next";

import {
  Navbar,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

const AlkiviNavBar = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    console.log("test");
    console.log(lng);
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <Navbar dark color="dark" expand="md">
        <NavbarBrand href="/">Alkivi Setup</NavbarBrand>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {t("language")}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem href="#" onClick={() => changeLanguage("fr")}>
                🇫🇷 Français
              </DropdownItem>
              <DropdownItem href="#" onClick={() => changeLanguage("en")}>
                🇬🇧 English
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    </div>
  );
};

export default AlkiviNavBar;
