import React from "react";
import { Button, Jumbotron } from "reactstrap";
import { FaHome } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import stepProps from "./Step";

const Step = ({ goToStep }) => {
  const { t } = useTranslation();

  return (
    <Jumbotron>
      <h1 className="display-3">{t("final.h1")}</h1>
      <p className="lead">{t("final.p")}</p>
      <hr className="my-2" />
      <p className="lead">
        <Button color="secondary" onClick={() => goToStep(1)}>
          ️<FaHome /> {t("global.home")}
        </Button>
      </p>
    </Jumbotron>
  );
};
Step.propTypes = stepProps;

export default Step;
