import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { FaHome } from "react-icons/fa";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

// define some custom breadcrumbs for certain routes (optional)
const routes = [];

// map & render your breadcrumb components however you want.
// each `breadcrumb` has the props `key`, `location`, and `match` included!
const Breadcrumbs = ({ breadcrumbs }) => (
  <div>
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index) => {
        let display = "";
        if (index === 0) {
          display = (
            <div>
              <FaHome />
            </div>
          );
        } else {
          display = breadcrumb.breadcrumb.props.children;
        }
        return (
          <BreadcrumbItem key={breadcrumb.key}>
            <NavLink to={breadcrumb.match.url}>{display}</NavLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  </div>
);

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withBreadcrumbs(routes)(Breadcrumbs);
