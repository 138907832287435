/* eslint-disable max-len */
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Container, Jumbotron, Button } from "reactstrap";

const Home = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Jumbotron>
        <h1 className="display-3">{t("home.h1")}</h1>
        <p className="lead">{t("home.lead")}</p>
        <hr className="my-2" />
        <p>{t("home.p1")}</p>
        <p>{t("home.p2")}</p>
        <p className="lead">
          <Button color="primary" to="/bootstrap" tag={NavLink}>
            {t("home.go")}
          </Button>
        </p>
      </Jumbotron>
    </Container>
  );
};

export default Home;
