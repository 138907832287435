import "regenerator-runtime/runtime";

import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware, { END } from "redux-saga";

import { history } from "../services";
import rootReducer from "../reducers";

export default function configureStore(preloadedState) {
  const historyRouterMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();
  let middleware = [sagaMiddleware, historyRouterMiddleware];

  if (process.env.NODE_ENV === "development") {
    const loggerMiddleware = createLogger();
    middleware = [...middleware, loggerMiddleware];
  }

  const store = createStore(
    rootReducer(history),
    preloadedState,
    compose(
      applyMiddleware(...middleware),
      process.env.NODE_ENV === "development" && window.devToolsExtension
        ? window.devToolsExtension()
        : (f) => f
    )
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
}
