import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { render } from "react-dom";
import "./index.css";

import Root from "./components/Root";
import configureStore from "./store/configureStore";
import rootSaga from "./sagas";
import { history } from "./services";
import * as serviceWorker from "./serviceWorker";

import "./i18n";

const store = configureStore();
store.runSaga(rootSaga);

const target = document.getElementById("root");
const node = <Root store={store} history={history} />;
render(node, target);

serviceWorker.unregister();
