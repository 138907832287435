import { camelizeKeys, decamelizeKeys } from "humps";
import "isomorphic-fetch";

const API_ROOT = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/`;

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(endpoint, method = "GET", data = {}) {
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  const init = {
    method,
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };

  if (Object.keys(data).length !== 0) {
    if (method === "GET" || method === "HEAD") {
      console.log("data to send");
      console.log(data);
    } else {
      init.body = JSON.stringify(decamelizeKeys(data));
    }
  }

  return fetch(fullUrl, init)
    .then((response) => {
      if (response.ok && response.status === 204) {
        return { json: {}, response };
      }
      return response.json().then((json) => ({ json, response }));
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return camelizeKeys(json);
    })
    .then(
      (response) => ({ response }),
      (error) => ({ error: error || "Something bad happened" })
    );
}

// api users
export const getUser = (login) => callApi(`users/${login}`);
export const getUsers = () => callApi("users");
export const delUser = (id) => callApi(`users/${id}`, "DELETE");
export const putUser = (id, user) => callApi(`users/${id}`, "PUT", user);
export const postUser = (user) => callApi("users", "POST", user);

// api login
export const postLogin = (token) => callApi("login", "POST", { token });
export const getLogin = () => callApi("login");
export const postLogout = () => callApi("logout", "POST");

// api roles
export const getRole = (id) => callApi(`roles/${id}`);
export const getRoles = () => callApi("roles");
export const delRole = (id) => callApi(`roles/${id}`, "DELETE");
export const putRole = (id, role) => callApi(`roles/${id}`, "PUT", role);
export const postRole = (role) => callApi("roles", "POST", role);

// api roles/users
export const postUserRole = (userId, name) =>
  callApi(`users/${userId}/roles`, "POST", { name });
export const delUserRole = (userId, name) =>
  callApi(`users/${userId}/roles`, "DELETE", { name });

// api minions
export const getMinion = (id) => callApi(`minions/${id}`);
export const getMinionName = (name) => callApi(`minions/${name}`);
export const getMinions = () => callApi("minions");
export const delMinion = (id) => callApi(`minions/${id}`, "DELETE");
export const putMinion = (id, minion) =>
  callApi(`minions/${id}`, "PUT", minion);
export const postMinion = (minion) => callApi("minions", "POST", minion);
export const postMinionTicket = (minion, data) =>
  callApi(`minions/${minion}/ticket`, "POST", data);
