import React from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <p>
          {t("global.help")}{" "}
          <a href="https://alkivi.fr" target="new">
            {t("global.contact")}
          </a>
        </p>
      </Col>
    </Row>
  );
};

export default Footer;
